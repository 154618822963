import React from "react";
import {
    TableCell,
    TableRow,

    Box,
    Grid,
    Button,
    Tooltip,
    Divider,
    Checkbox,
    TextField,
    Typography,
    IconButton,
    FormControlLabel
} from "@mui/material";
import {
    makeStyles,
    withStyles
} from "@mui/styles";
import {
    Delete as DeleteIcon
} from "@mui/icons-material";
import {
    IMaskInput
} from "react-imask";
import {
    convertorNumber, convertorNumberCircumcision
} from "../../../../../helper/convertor";
import agent from "../../../../../agent/agent";
import urls from "../../../../../variables/urls";
import BigNumber from "bignumber.js";
import {palette} from "../../../../../theme/common";
import {
    AutocompleteExtraProductSetParents
} from "../../../../products/ProductEdit/components/MainForm/index";

class ProductRow extends React.PureComponent {
    constructor(props) {
        super(props);

        this.state = {
            apiProduct: {},
            orderProduct: {...props.product},

            newPriceValue: "",
            newPricePercent: "",

            isOtherPrice: false,
            isNewPrice: false,
            errorOtherPrice: false,
        }

        this.timeOutUpdateProduct = null;
    }

    componentDidMount = async () => {
        await this.getProduct();
    }

    getProduct = async () => {
        const { userCityId, websiteId } = this.props;

        const expand = 'expand=extraOptions';
        const fields = 'fields=id,price_old,price,extraOptions';
        const ngrestCallType = "ngrestCallType=update";

        const search = [ngrestCallType, fields, expand].join('&')
        const PropsProduct = JSON.parse(this.props.product);
        const product = await agent.get(`${urls.getProduct}/${PropsProduct.product_id}?${search}&city_id=${ userCityId }&website_id=${websiteId}`).then((res) => {
            return res.data
        }).catch(() => {
            return {}
        });
        const isChangedPrice = Boolean(+product.price !== +PropsProduct.price);

        let priceOptions = 0;
        if (!!isChangedPrice) {
            (PropsProduct?.options || []).map((option) => {
                const apiOption = (product?.extraOptions || []).find((t) => t.id === option.id);
                (option?.variants || []).map((variant) => {
                    const apiVariant = (apiOption?.variants || []).find((t) => t.id === variant.id)?.price_current || 0;
                    priceOptions = new BigNumber(priceOptions).plus(apiVariant).toNumber()
                })
            })
        }


        let newPriceValue = "";
        if (isChangedPrice) {
            newPriceValue = new BigNumber(product.price).plus(priceOptions).toString()
        }

        this.setState({
            apiProduct: product,

            newPriceValue: newPriceValue,

            isOtherPrice: isChangedPrice,
            isNewPrice: isChangedPrice,
            errorOtherPrice: isChangedPrice
        }, async () => {
            if (isChangedPrice) {
                await this.updateProduct();
            }
        })
    }

    changeNewPriceValue = ({target}) => {
        clearTimeout(this.timeOutUpdateProduct)

        const {value} = target;
        const product = JSON.parse(this.props.product);
        const productPrice = +product.price;
        const productPriceTotal = this._calcTotalPriceProduct(product);

        const newPercent = Boolean(value === "") ? "" : 100 - (+value / productPriceTotal) * 100;

        this.setState({
            newPriceValue: value,
            newPricePercent: convertorNumberCircumcision(newPercent, 2, '.')
        }, () => {
            this.timeOutUpdateProduct = setTimeout(() => {
                this.updateProduct();
            }, 500)
        })
    }
    changeNewPricePercent = ({target}) => {
        clearTimeout(this.timeOutUpdateProduct)

        const {value} = target;
        const product = JSON.parse(this.props.product);
        const productPriceTotal = this._calcTotalPriceProduct(product);
        const newPrice = Boolean(value === "") ? "" : productPriceTotal - ((+value / 100) * productPriceTotal);

        this.setState({
            newPricePercent: value,
            newPriceValue: String(newPrice)
        }, () => {
            this.timeOutUpdateProduct = setTimeout(() => {
                this.updateProduct();
            }, 500)
        })
    }
    changeIsOtherPrice = (event, value) => {
        this.setState({
            isOtherPrice: value
        }, () => {
            this.updateProduct();
        })
    }
    changeProductData = ({target}) => {
        clearTimeout(this.timeOutUpdateProduct)
        const {value, name} = target;

        let product = JSON.parse(this.props.product);
        let productData = Boolean(product.external_data === '[]') ? {} : JSON.parse(product.external_data || '{}');
        productData[name] = value;
        product.external_data = JSON.stringify(productData);

        this.props.onChangeProductExternalData(
            product,
            this.props.index
        );
    }
    changeCollectionId = (event) => {
        const _id = [...(event?.value || [])].pop() || null;
        this.changeProductData({
            target: {
                value: _id,
                name: "collection_id"
            }
        });
    }
    changeProductDiscounts = (type, { target }) => {
        const { value } = target;

        let product = JSON.parse(this.props.product);

        const discountIndex = product.discounts.findIndex((t) => Boolean(String(t.type) === type));

        let discounts = [...product.discounts];
        discounts[discountIndex].value = value;

        this.props.onChangeProductDiscounts(discounts, this.props.index);
    }

    changeProductQuantity = ({target}) => {
        const {value} = target;

        this.props.onChangeProductQuantity(value, this.props.index)
    }

    onDeleteProductFromList = () => {
        const product = JSON.parse(this.props.product);
        this.props.onDeleteProductFromList(
            product,
            this.props.index,
            false
        )
    }
    onChangeOptionsProduct = () => {
        const product = JSON.parse(this.props.product);

        this.props.onChangeOptionsProduct(
            product,
            this.props.index,
            {},
            false
        )
    }

    updateProduct = ({resetOtherPrice} = {}) => {
        let product = JSON.parse(this.props.product);
        const newPrice = Boolean(this.state.isOtherPrice) ? this.state.newPriceValue : "";

        product.newPrice = newPrice;
        if (!!newPrice) {
            product.options = [...(product?.options || [])].map((option) => {
               const variants = [...(option?.variants || [])].map((variant) => {
                   return {
                       ...variant,
                       price: 0,
                       price_save: variant.price
                   }
               });
               return {
                   ...option,
                   variants
               }
            });
        } else {
            product.options = [...(product?.options || [])].map((option) => {
                const variants = [...(option?.variants || [])].map((variant) => {
                    let newVariant = {
                        ...variant,
                        price: variant?.price_save || variant.price
                    }
                    delete variant.price_save;

                    return {
                        ...newVariant
                    }
                });
                return {
                    ...option,
                    variants
                }
            });
        }

        this.props.onChangeProductFull(
            product,
            this.props.index
        );

        if (resetOtherPrice) {
            this.setState({
                isOtherPrice: false
            })
        }
    }
    updateProductAsync = async ({resetOtherPrice} = {}) => {
        let product = JSON.parse(this.props.product);
        const newPrice = Boolean(this.state.isOtherPrice) ? this.state.newPriceValue : "";

        product.newPrice = newPrice;
        if (!!newPrice) {
            product.options = [...(product?.options || [])].map((option) => {
               const variants = [...(option?.variants || [])].map((variant) => {
                   return {
                       ...variant,
                       price: 0,
                       price_save: variant.price
                   }
               });
               return {
                   ...option,
                   variants
               }
            });
        } else {
            product.options = [...(product?.options || [])].map((option) => {
                const variants = [...(option?.variants || [])].map((variant) => {
                    let newVariant = {
                        ...variant,
                        price: variant?.price_save || variant.price
                    }
                    delete variant.price_save;

                    return {
                        ...newVariant
                    }
                });
                return {
                    ...option,
                    variants
                }
            });
        }

        await this.props.onChangeProductFull(product, this.props.index);
    }


    changeIsOtherPriceAsync = async (value) => {
        await this.setState({
            isOtherPrice: value
        }, async () => {
            await this.updateProduct();
        })
    }
    resetOtherPrice = async () => {
        if (!this.state.isNewPrice) {
            return
        }

        const PropsProduct = JSON.parse(this.props.product);
        await this.setState({
            newPriceValue: String(PropsProduct?.price),
            isNewPrice: false,
            errorOtherPrice: false,
        });
        await this.updateProductAsync();
        await this.setState({ isOtherPrice: false });
    }


    _calcTotalPrice = (product) => {
        const price = Boolean(this.state.isOtherPrice) ? (this.state.newPriceValue || product?.newPrice || product.price) : product.price;

        const priceOptions = (product?.options || [])
          .reduce((value, option) => {
              const variantPrice = +option?.variants?.[0]?.price || 0;
              return value + variantPrice
          }, 0);

        const discountValue6 = Number.parseFloat([...(product?.discounts || [])]
          .find((t) => Boolean(String(t.type) === "6"))?.value || "0");

        const discountValue3 = Number.parseFloat([...(product?.discounts || [])]
          .find((t) => Boolean(String(t.type) === "3"))?.value || "0");

        const discountValue8 = Number.parseFloat([...(product?.discounts || [])]
          .find((t) => Boolean(String(t.type) === "8"))?.value || "0");

        return new BigNumber(price).plus(priceOptions).minus(discountValue6).minus(discountValue8).minus(discountValue3).toNumber();
    }
    _calcTotalPriceOptions = (product) => {
        return (product?.options || [])
          .reduce((value, option) => {
              const variantPrice = +option?.variants?.[0]?.price || 0;
              return value + variantPrice
          }, 0);
    }
    _calcTotalPriceProduct = (product) => {
        const price = product.price;

        const priceOptions = (product?.options || [])
          .reduce((value, option) => {
              const variantPrice = +option?.variants?.[0]?.price_save || +option?.variants?.[0]?.price_value || 0;
              return value + variantPrice
          }, 0);

        return new BigNumber(price).plus(priceOptions).toNumber();
    }
    _calcProductPriceOptions = (product) => {
        let optionsPrice = (product?.options || []).reduce((value, option) => {
            const variantPrice = (option?.variants || []).reduce((value, variant) => {
                return new BigNumber(value).plus(variant.price).toNumber()
            }, 0);
            return new BigNumber(value).plus(variantPrice).toNumber()
        }, 0);

        return new BigNumber(product.price).plus(optionsPrice).toNumber()
    }

    _getCalcTotalPrice = () => {
        const product = JSON.parse(this.props?.product || "{}");
        if (Object.keys(product).length <= 0) {
            return 0
        }

        return this._calcTotalPrice(product) * product.quantity
    }

    render() {
        const {
            newPriceValue,
            newPricePercent,

            isOtherPrice,
            isNewPrice,
            errorOtherPrice
        } = this.state;
        const {classes} = this.props;
        const product = JSON.parse(this.props.product);
        const productData = Boolean(product?.external_data === '[]') ? {} : JSON.parse(product.external_data || '{}');
        const extraProduct = product.product || {};
        const productPrice = this._calcTotalPrice(product);
        const disableButtonChangeOption = Boolean(
          !product.options ||
          product.options === "{}" ||
          Boolean(Array.isArray(product.options) && product.options.length <= 0)
        )
        const isVisibleSelectCollection = Boolean(Number.parseFloat(product?.product?.productSetAllParentsCount || 0) === 0);
        const isErrorTotalPrice = Boolean(productPrice < 0);

        return (
            <TableRow hover>
                <TableCell>
                    <Grid container spacing={2} alignItems="center">
                        <Grid item sx={{flex: 1}}>
                            <Grid container spacing={1} sx={{marginBottom: "8px"}} alignItems="center">
                                <Grid item>
                                    <Tooltip
                                        title={(<React.Fragment>
                                            <img
                                                src={`${process.env.REACT_APP_HOST_API}api/storage/image/${extraProduct.image_id}_w-600.webp`}
                                                style={{
                                                    maxWidth: 300,
                                                    maxHeight: 300,
                                                    borderRadius: 4,
                                                    objectFit: "contain"
                                                }}
                                            />
                                        </React.Fragment>)}
                                        arrow
                                    >
                                        <img
                                            src={`${process.env.REACT_APP_HOST_API}api/storage/image/${extraProduct.image_id}_w-200.webp`}
                                            style={{width: 40, height: 40, borderRadius: 4, objectFit: "cover"}}
                                        />
                                    </Tooltip>
                                </Grid>
                                <Grid item>
                                    <Box className={classes.textMedium}>{extraProduct.name}</Box>
                                </Grid>
                            </Grid>
                            <Box mb="8px">
                                <Typography variant="caption" component="div" sx={{marginBottom: "2px"}}>Артикул для
                                    CRM:</Typography>
                                <TextField
                                    value={productData.crm_sku || ""}
                                    size="small"
                                    name="crm_sku"
                                    fullWidth
                                    className={classes.textField}
                                    onChange={this.changeProductData}
                                />
                            </Box>
                            <Box mb="16px">
                                <Typography variant="caption" component="div" sx={{marginBottom: "2px"}}>
                                    Комментарий для CRM:
                                </Typography>
                                <TextField
                                    value={productData.crm_comment || ""}
                                    size="small"
                                    name="crm_comment"
                                    fullWidth
                                    multiline
                                    rows={3}
                                    sx={{maxWidth: "420px!important"}}
                                    className={classes.textField}
                                    onChange={this.changeProductData}
                                />
                            </Box>

                            <OptionsInfo
                              product={product}
                            />

                            {Boolean(isVisibleSelectCollection) && (
                              <Box maxWidth={420} mt={3}>
                                  <AutocompleteExtraProductSetParents
                                    initValues={Boolean(productData?.collection_id) ? [productData?.collection_id] : []}
                                    onChange={this.changeCollectionId}
                                  />
                              </Box>
                            )}
                        </Grid>
                        <Grid item>
                            <Button
                                variant="contained"
                                size="small"
                                className={classes.button}
                                disabled={disableButtonChangeOption}
                                onClick={this.onChangeOptionsProduct}
                            >Настроить</Button>
                        </Grid>
                    </Grid>
                </TableCell>
                <TableCell>
                    {product.product.code}
                </TableCell>
                <TableCell>
                    <PriceInfo
                      product={product}
                      isOtherPrice={Boolean(errorOtherPrice)}
                    />
                    <Box mt={2}/>
                    <FormControlLabel
                        sx={{margin: "-9px 0 -9px -9px"}}
                        control={(<Checkbox checked={isOtherPrice} value={isOtherPrice} size="small" onChange={this.changeIsOtherPrice}/>)}
                        label="Другая цена"
                    />
                    {Boolean(isOtherPrice) && (
                        <Box mt="8px">
                            <Typography variant="caption" component="div" sx={{marginBottom: "2px"}}>
                                Новая цена товара
                            </Typography>
                            <Box mb="2px">
                                <TextField
                                    value={newPriceValue}
                                    size="small"
                                    fullWidth
                                    sx={{maxWidth: "120px!important"}}
                                    placeholder="Новая цена"
                                    className={classes.textField}
                                    InputProps={{inputComponent: TextMaskCustom}}
                                    onChange={this.changeNewPriceValue}
                                />
                            </Box>
                            <Box mb="2px">
                                <TextField
                                    value={newPricePercent}
                                    size="small"
                                    fullWidth
                                    sx={{maxWidth: "120px!important"}}
                                    className={classes.textField}
                                    placeholder="% скидки"
                                    inputProps={{
                                        scale: 2,
                                        signed: true
                                }}
                                    InputProps={{inputComponent: TextMaskCustom}}
                                    onChange={this.changeNewPricePercent}
                                />
                            </Box>
                        </Box>
                    )}
                </TableCell>
                <TableCell>
                    <PromoInfo
                      product={product}
                      classes={classes}
                      onChange={this.changeProductDiscounts.bind(null, "3")}
                    />
                </TableCell>
                <TableCell>
                    <DiscountInfo
                      product={product}
                      classes={classes}
                      onChange={this.changeProductDiscounts.bind(null, "6")}
                    />
                </TableCell>
                <TableCell>
                    <TextField
                        value={product.quantity}
                        size="small"
                        fullWidth
                        sx={{maxWidth: "60px!important"}}
                        className={classes.textField}
                        inputProps={{
                            scale: 0
                        }}
                        InputProps={{inputComponent: TextMaskCustom}}
                        onChange={this.changeProductQuantity}
                    />
                </TableCell>
                <TableCell>
                    {`${convertorNumber((productPrice * product.quantity), 2, ',')} руб.`}

                    {Boolean(isErrorTotalPrice) && (
                      <span style={{
                          display: "flex",
                          fontSize: "12px",
                          maxWidth: "135px",
                          color: "#e53935",
                          lineHeight: "12px",
                          marginTop: "4px",
                      }}>Цена должна быть больше или равна нулю</span>
                    )}
                </TableCell>
                <TableCell align="right">
                    <Grid container spacing={1} alignItems="center" justifyItems="flex-end">
                        <Grid item>
                            <Tooltip title="Удалить товар из заказа" arrow>
                                <IconButton onClick={this.onDeleteProductFromList}>
                                    <DeleteIcon color="error" sx={{fontSize: 24}}/>
                                </IconButton>
                            </Tooltip>
                        </Grid>
                    </Grid>
                </TableCell>
            </TableRow>
        )
    }
}

const PromoInfo = React.memo((props) => {
    const {
        product,
        classes,
        onChange
    } = props;

    const [value, setValue] = React.useState(() => {
        return String([...(product?.discounts || [])]
          .find((t) => Boolean(String(t.type) === "3"))?.value || "")
    });
    const handleChangeValue = ({ target }) => {
        const { value: _value } = target;
        if (String(_value) === String(value)) {
            return
        }

        setValue(String(_value));
        onChange({ target })
    };

    return (
      <>
          <TextField
            value={value}
            size="small"
            fullWidth
            inputProps={{ scale: 2 }}
            className={classes.textField}
            sx={{maxWidth: "82px!important"}}
            InputProps={{inputComponent: TextMaskCustom}}
            onChange={handleChangeValue}
          />
      </>
    )
});
const DiscountInfo = React.memo((props) => {
    const {
        product,
        classes,
        onChange
    } = props;

    const [value, setValue] = React.useState(() => {
        return String([...(product?.discounts || [])]
          .find((t) => Boolean(String(t.type) === "6"))?.value || "")
    });
    const handleChangeValue = ({ target }) => {
        const { value: _value } = target;
        if (String(_value) === String(value)) {
            return
        }

        setValue(String(_value));
        onChange({ target })
    };

    return (
      <>
          <TextField
            value={value}
            size="small"
            fullWidth
            inputProps={{ scale: 2 }}
            className={classes.textField}
            sx={{maxWidth: "82px!important"}}
            InputProps={{inputComponent: TextMaskCustom}}
            onChange={handleChangeValue}
          />
      </>
    )
});
const PriceInfo = React.memo((props) => {
    const {
        product,
        isOtherPrice
    } = props;
    const classes = useStyles();
    const cost = convertorNumber(convertorNumberCircumcision(product.cost, 2, '.'), 2, '.');
    const price = convertorNumber(convertorNumberCircumcision(product.price, 2, '.'), 2, '.');

    return (
      <Box>
          <div className={classes.priceRow}>
              Цена товара: <span>{price} ₽</span>
          </div>
          <Box mt={1}/>
          <div className={classes.priceRow}>
              Цена товара с параметрами: <span>{cost} ₽</span>
          </div>
          {Boolean(isOtherPrice) && (
            <>
                <Box mt={1}/>
                <div className={classes.priceOther}>
                    Актуальная цена уже другая
                </div>
            </>
          )}
      </Box>
    )
});
const OptionsInfo = React.memo((props) => {
    const {
        product
    } = props;
    const classes = useStyles();
    const options = (product?.options || []);

    if (options.length <= 0) {
        return null
    }
    return (
      <Box container spacing="4px">
          {options.map((option) => {
              const price = option?.variants?.[0]?.price || 0;
              const optionItem = (option.variants || [])?.[0] || {};
              const priceCurrent = option?.variants?.[0]?.price_current;
              const isNewPrice = Boolean(!!priceCurrent && price !== priceCurrent);
              const variantGroupName = (option?.variantGroups || []).find((t) => Boolean(String(t.id) === String(optionItem?.variant_group_id)))?.name || '';

              return (
                <Box className={classes.optionsInfoRow} mt={1}>
                    <span className="--name">
                        { option?.name }: <span dangerouslySetInnerHTML={{
                            __html: `${[variantGroupName, option?.variants?.[0]?.name].filter((t) => !!t).join(' ')}`
                        }}/>
                    </span>
                    <span className="--price">
                            <br/>(Текущая цена: {price} ₽)<span> </span>
                        </span>
                    {Boolean(isNewPrice) && (
                      <span className="--current-price">(Актуальная цена: {priceCurrent} ₽)</span>
                    )}
                </Box>
              )
          })}
      </Box>
    )
});

const TextMaskCustom = React.forwardRef(function TextMaskCustom(props, ref) {
    const {onChange, ...other} = props;

    const handleOnAccept = (value) => {


        if (value === props.value) {
            return
        }

        onChange({target: {name: props.name, value}})
    }

    return (
        <IMaskInput
            mask={Number}
            thousandsSeparator=" "
            radix="."
            mapToRadix={[',']}
            scale={2}
            unmask={true}

            inputRef={ref}

            onAccept={handleOnAccept}

            {...other}
        />
    );
});

const styles = {
    textMedium: {
        fontWeight: "500"
    },
    textField: {
        maxWidth: 240,
        "& .MuiOutlinedInput-root": {
            height: "auto"
        },
        "& .MuiOutlinedInput-root .MuiOutlinedInput-input": {
            padding: "0 4px",
        }
    },
    button: {
        minWidth: "initial",
        padding: "4px 8px",
        height: "auto",
        minHeight: "initial",
        fontSize: 12,
        lineHeight: "14px",
        textTransform: "initial",
    }
}
ProductRow = withStyles(styles)(ProductRow)

const useStyles = makeStyles(() => ({
    priceRow: {
        display: "flex",
        flexDirection: "column",
        color: "#434343",
        fontSize: "12px",
        lineHeight: "16px",

        "& span": {
            color: "#434343",
            fontVariantNumeric: "lining-nums tabular-nums",
            fontSize: "14px",
            lineHeight: "16px",
            letterSpacing: "-0.14px",
        }
    },
    priceOther: {
        color: "#0057FF",
        fontSize: "14px",
        color: palette.error.main
    },

    optionsInfoRow: {
        color: "#434343",
        fontSize: "14px",
        lineHeight: "130%",

        "& .--name": {},
        "& .--price": {
            opacity: 0.5
        },
        "& .--current-price": {
            color: palette.error.main,
            opacity: 0.5
        },
    },
}));

export default ProductRow
